import React, {FC, useEffect, useState} from "react";
import {DialogSimple} from "../Reusable/DialogSimple";
import {EstablishedRelationship, MissingRelationship} from "../../api/types";
import {Button, Grid, Typography} from "@mui/material";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {awinRelationshipsEstablishRelationship} from "../../api/services";
import {toast} from "react-toastify";
import {DialogSimpleFieldDropdown} from "../Reusable/DialogSimpleFieldDropdown";


export const DialogEstablishRelationship: FC<{
  missingRelationship: MissingRelationship,
  establishedRelationship: EstablishedRelationship,
  onDismiss: (reload: boolean) => void,
}> = ({
  missingRelationship,
  establishedRelationship,
  onDismiss,
}) => {

  const [totalCommission, setTotalCommission] = useState<number>()
  const [platformCommission, setPlatformCommission] = useState<number>()
  const [publisherOrganizationCommission, setPublisherOrganizationCommission] = useState<number>()
  const [individualCreatorCommission, setIndividualCreatorCommission] = useState<number>()
  const [commissionSchemeId, setCommissionSchemeId] = useState<string>("")

  const [advertiserOrganizationId, setAdvertiserOrganizationId] = useState<string>("")
  const [publisherOrganizationId, setPublisherOrganizationId] = useState<string>("")
  const [advertiserOrganizationName, setAdvertiserOrganizationName] = useState<string>("")
  const [publisherOrganizationName, setPublisherOrganizationName] = useState<string>("")

  const [moneyManagement, setMoneyManagement] = useState<'Platform' | 'Awin'>()

  const [error, setError] = useState<string>()

  useEffect(() => {
    if (totalCommission <= 0 || totalCommission != (platformCommission + publisherOrganizationCommission + individualCreatorCommission)) {
      setError("Total commission must be larger than 0.0, also total == platformCommission + publisherOrganizationCommission + individualCreatorCommission")
    } else {
      setError(undefined)
    }
    if (!commissionSchemeId) {
      setError("Commission scheme Id must be specified")
    }
  }, [totalCommission, platformCommission, publisherOrganizationCommission, individualCreatorCommission, commissionSchemeId])

  useEffect(() => {
    setTotalCommission(establishedRelationship?.totalCommission ?? 0.0)
    setPlatformCommission(establishedRelationship?.platformCommission ?? 0.0)
    setPublisherOrganizationCommission(establishedRelationship?.publisherOrganizationCommission ?? 0.0)
    setIndividualCreatorCommission(establishedRelationship?.individualCreatorCommission ?? 0.0)
    setCommissionSchemeId(establishedRelationship?.commissionSchemeId ?? missingRelationship?.commissionSchemeId)
    setMoneyManagement(establishedRelationship?.moneyManagement ?? "Platform")

    setAdvertiserOrganizationName(establishedRelationship?.advertiserOrganizationName ?? missingRelationship?.advertiserOrganizationName)
    setPublisherOrganizationName(establishedRelationship?.publisherOrganizationName ?? missingRelationship?.publisherOrganizationName)
    setAdvertiserOrganizationId(establishedRelationship?.advertiserOrganizationId ?? missingRelationship?.advertiserOrganizationId)
    setPublisherOrganizationId(establishedRelationship?.publisherOrganizationId ?? missingRelationship?.publisherOrganizationId)
  }, [missingRelationship, establishedRelationship])

  const save = () => {

    awinRelationshipsEstablishRelationship({
      advertiserOrganizationId: advertiserOrganizationId,
      publisherOrganizationId: publisherOrganizationId,
      totalCommission: totalCommission,
      platformCommission: platformCommission,
      publisherOrganizationCommission: publisherOrganizationCommission,
      individualCreatorCommission: individualCreatorCommission,
      commissionSchemeId: commissionSchemeId,
      moneyManagement: moneyManagement,
    }).then(() => {
      toast.success("Awin relationship updated")
      onDismiss(true)
    })

  }

  return (
    <DialogSimple
      open={Boolean(missingRelationship || establishedRelationship)}
      title={"Establish Awin relationship"}
      onDismiss={onDismiss}
      maxWidth={"sm"}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Advertiser organization: {advertiserOrganizationName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Publisher organization: {publisherOrganizationName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldDropdown props={{ sx: { width: "100%" }}} label={"Money management"} value={moneyManagement} setValue={setMoneyManagement} values={[{ key: "Awin", label: "Awin" }, { key: "Platform", label: "Platform" }]}/>
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldText type={"number"} label={"Total commission"} value={totalCommission} setValue={(v) => setTotalCommission(parseFloat(v))} error={false} helperText={"Value in fractional numbers, enter 10.5 to specify 10.5%"} />
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldText type={"number"} label={"Platform commission"} value={platformCommission} setValue={(v) => setPlatformCommission(parseFloat(v))} error={false} helperText={"Value in fractional numbers, enter 10.5 to specify 10.5%"} />
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldText type={"number"} label={"Publisher organization commission"} value={publisherOrganizationCommission} setValue={(v) => setPublisherOrganizationCommission(parseFloat(v))} error={false} helperText={"Value in fractional numbers, enter 10.5 to specify 10.5%"} />
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldText type={"number"} label={"Individual creator commission"} value={individualCreatorCommission} setValue={(v) => setIndividualCreatorCommission(parseFloat(v)) } error={false} helperText={"Value in fractional numbers, enter 10.5 to specify 10.5%"} />
          </Grid>
          <Grid item xs={12}>
            <DialogSimpleFieldText label={"Commission scheme ID"} value={commissionSchemeId} setValue={setCommissionSchemeId} error={false} />
          </Grid>
          <Grid item xs={12} sx={{height: "60px"}}>
            <Typography sx={{ color: "red" }}>
              { error }
            </Typography>
          </Grid>
        </Grid>
      }
      actions={
        <>
          <Button onClick={onDismiss}>Cancel</Button>
          <Button variant={"contained"} disabled={Boolean(error)} onClick={save}>Save</Button>
        </>
      }
    />
  )
}