import {FC, useMemo} from "react";
import {DashboardCharts, StatisticsAggregate} from "../../api/types";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import {formatSeconds} from "../../utils/timeFormatter";

type AggregateType = StatisticsAggregate["aggregateType"]

export const disabledTextColor = "#b0b0b0"

export interface DataChartDefinition {
  type: 'line' | 'area',
  title: string,
  data: DashboardCharts,
  productGrouping?: 'Sum' | 'Average',
  yAxis?: {
    show: boolean,
    type: 'Absolute' | 'Time',
    scale: 'Standard' | 'Logarithmic',
  },
  series: {
    aggregateType: AggregateType,
    title: string,
    currentValueAnnotation?: string,
    type: 'value' | 'sessionCount' | 'count'
  }[],
  noDataText: string,
  disabled?: boolean,
}

const DataChart: FC<DataChartDefinition> = ({
  type,
  title,
  data,
  productGrouping = 'Sum',
  yAxis = {
    show: true,
    type: 'Absolute',
    scale: 'Standard',
  },
  series,
  noDataText,
  disabled,
}) => {

  const formatDate = (date: string): string => {
    let format = date
    // @ts-ignore
    let m = moment(date * 1000)
    switch (data.periodType) {
      case "MINUTE":
        format = m.format('HH:mm')
        break
      case "FIFTEEN_MINUTES":
        format = m.format('HH:mm')
        break
      case "HOURLY":
        format = m.format('HH:mm')
        break
      case "DAILY":
        format = m.format('MM-DD')
        break
      case "WEEKLY":
        format = m.format('MM-DD')
        break
      case "MONTHLY":
        format = m.format('yyyy-MM')
        break
      case "QUARTERLY":
        format = m.format('yyyy-MM')
        break
      case "YEARLY":
        format = m.format('yyyy')
        break
    }

    return format
  }

  const productLevelData = (aggregates: StatisticsAggregate[]): StatisticsAggregate[] =>  {

    let grouped = _.groupBy(aggregates, (a) => a.periodFrom)
    let periods = Object.keys(grouped)
    let mapped = periods.map((period) => {
      let aggregates = grouped[period]
      let first = aggregates[0]
      if (first.productLevelDataAdjusted) {
        return first
      } else {
        let count = 0
        let value = 0
        if (productGrouping === 'Average') {

          aggregates.forEach((agg => {
            count += agg.count
            value += (agg.value * agg.count)
          }))
        } else {
          // Sum

          aggregates.forEach((agg => {
            count += agg.count
            value += agg.value
          }))
        }

        if (productGrouping === 'Average') {
          value = value / count
        }

        first.count = count
        first.value = value
        first.productLevelDataAdjusted = true
        return first
      }
    })
    return mapped
  }

  const seriesObject = useMemo<ApexAxisChartSeries | ApexNonAxisChartSeries>(() => {
    return series.map((s) => {

      let seriesDataByPeriodFrom = _.groupBy(productLevelData(data.charts[s.aggregateType] ?? []), (a) => a.periodFrom)

      return {
        name: s.title,
        data: data.periods.map(period => {
          let item = seriesDataByPeriodFrom[period]?.[0]
          if (s.type === 'value') {
            return item?.value ?? 0
          } else if (s.type === 'sessionCount') {
            return item?.sessionCount ?? 0
          } else if (s.type === 'count') {
            return item?.count ?? 0
          }
        })
      }
    })
  }, [data, series])

  const options = useMemo<ApexCharts.ApexOptions>(() => {
    return {
      grid: {
        padding: {
          left: 20,
          right: 20,
        }
      },
      chart: {
        id: 'id',
        toolbar: {
          show: false
        },
        zoom: {
          type: 'x',
          enabled: false,
        },
        animations: {
          enabled: false,
        }
      },
      title: {
        text: title,
        style: {
          color: disabled ? disabledTextColor : "#000",
        },
        align: 'left',
      },
      stroke: {
        width: 5,
        curve: 'smooth',
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0, // hide bubbles on each data point
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        },
      },
      annotations: {
        points: series.filter(s => s.currentValueAnnotation).map((s, index) => {
          // @ts-ignore
          let seriesData = seriesObject[index].data
          let currentValueY = seriesData[seriesData.length - 1]
          return {
            x: data.periods[data.periods.length - 1] * 1000,
            y: currentValueY,
            marker: {
            size: 8,
          },
            label: {
              orientation: 'horizontal',
              text: s.currentValueAnnotation.replace("{y}", currentValueY),
              textAnchor: 'end',
            }
          }
        }),
      },
      xaxis: {
        categories: data.periods.map(a => a * 1000),
        type: 'datetime',
        tickAmount: 8,
        labels: {
          rotate: 0,
          rotateAlways: true,
          datetimeUTC: true,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm',
          }
        }
      },
      yaxis: {
        logarithmic: yAxis.scale === 'Logarithmic',
        labels: {
          show: yAxis.show === true,
          formatter(val: number, opts?: any): string | string[] {
            if (yAxis.type === 'Time') {
              return formatSeconds(val)
            } else {
              return "" + Math.round(val)
            }
          }
        },
        tickAmount: 6,
      },
      noData: {
        text: noDataText,
        style: {
          color: "#666",
        },
      },
    }
  }, [type, data])

  return (
    <ReactApexChart
      type={type}
      options={options}
      series={seriesObject}
      height={"100%"}
    />
  )
}

export default DataChart