import React, {FC, ReactElement} from "react";
import {CardMedia} from "@mui/material";
import {Card, CardActionArea, CardContent, Grid, Typography} from "@mui/material";

interface GridComponentParams {
  thumbnail: string | ReactElement,
  label: string | ReactElement,
  selected: boolean,
  bottomElement?: ReactElement,
}

export const formatLabelLength = (label, maxLen = 50) => {
  if (label.length > maxLen) {
    return label.substring(0, maxLen) + "..."
  } else {
    return label
  }
}

export const GridComponent: FC<GridComponentParams> = ({
  thumbnail,
  label,
  selected,
  bottomElement,
}) => {

  const styles = {
    image: {
      width: "100%",
      height: 100,
      objectFit: "cover",
    },
    rootContainer: {

    },
    rootContainerSelected: {
      "box-shadow": "0px 0px 10px rgba(85, 105, 255, 1)",
    },
    contentContainer: {
      padding: "10px",
    },
    contentContainerSelected: {
      padding: "10px",
      backgroundColor: "#e7f0ff",
    },
    label: {
      fontSize: 12,
      wordBreak: "break-all",
    }
  }



  return (
    <Card variant={"elevation"} sx={selected ? styles.rootContainerSelected : styles.rootContainer }>
      <CardActionArea>
        { typeof thumbnail === 'string' ? (
          // @ts-ignore
          <CardMedia style={styles.image} image={thumbnail}/>
        ) : (<>{thumbnail}</>)
        }
        <CardContent sx={selected ? styles.contentContainerSelected : styles.contentContainer}>

          { typeof label === 'string' ? (
            <Grid container justifyContent={"space-between"}>
              <Grid item xs sx={{ display: "flex", alignItems: "center", height: "36px"}}>
                <Typography sx={styles.label}>
                  {formatLabelLength(label)}
                </Typography>
              </Grid>
            </Grid>
            ) : (
            <>
              {label}
            </>
          )}
          { bottomElement && (
            <>
              {bottomElement}
            </>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}