import React, {FC, useState} from "react";
import {GalleryItem, upload} from "./helpers";
import {toast} from "react-toastify";
import {Checkbox, FormControlLabel, Grid, IconButton, ListItemText, Tooltip, Typography} from "@mui/material";
import {Box, Button} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from '@mui/icons-material/Info';
import {ProgressIndicator} from "../Reusable/ProgressIndicator";
import {DialogSimple} from "../Reusable/DialogSimple";
import {useAppContext} from "../../contexts/PermissionContext";
import {mediaStorageSetAvailability} from "../../api/services";

export const GalleryUploadDialog: FC<{ open: boolean, onUpload: (items: GalleryItem[]) => void, onDismiss: () => void }> = ({ open, onUpload, onDismiss }) => {

  const { constants, permissions } = useAppContext()

  const [files, setFiles] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [progress, setProgress] = useState(0)
  const [isUploading, setIsUploading] = useState(false)
  const [availableToCreators, setAvailableToCreators] = useState(false)
  const reset = () => {
    setFiles([])
    setIsUploading(false)
    setFileNames([])
    setProgress(0)
  }

  const removeFileByName = (name) => {
    let index = files.findIndex(f => f.name === name)
    if (index > -1) {
      files.splice(index, 1)
      setFiles(files)
      setFileNames(files.map(f => f.name))
    }
  }

  const doUpload = async () => {
    setProgress(0)
    setIsUploading(true)

    let uploaded = await upload(files, setProgress, 'GALLERY')

    if (availableToCreators) {
      for (const g of uploaded) {
        await mediaStorageSetAvailability(g.id, 'MembersAndCreators')
      }
    }

    reset()
    onUpload(uploaded)
    toast.success("Asset uploaded successfully")
  }

  const dismiss = () => {
    if (!isUploading) {
      onDismiss()
    }
  }

  return (
    <DialogSimple
      open={open}
      onDismiss={dismiss}
      canClose={!isUploading}
      title={"Upload new assets"}
      maxWidth={"xs"}
      content={
        <Box>
          <input id="gallery-upload-file" name="gallery-upload-file" type="file" accept={constants?.supportedMediaTypes?.join(", ")} multiple={true} style={{ display: "none" }} onChange={(e) => {
            let appendFiles = Array.from(e.target.files || [])
            let newFiles = files.concat(appendFiles)
            setFiles(newFiles)
            setFileNames(newFiles.map(f => f.name))
          }}/>

          <Grid container alignItems={"center"}>
            <Grid item>
              <Button style={{ marginBottom: 10, marginTop: 10 }} component={"label"} variant={"contained"} htmlFor="gallery-upload-file">Browse files</Button>
            </Grid>
            <Grid item>
              <Tooltip title={
                <Box>
                  <>
                    <Typography>
                      Supported file types:
                    </Typography>
                    {constants?.supportedMediaTypes?.map(t => (

                      <Typography>
                        {t}
                      </Typography>
                    ))}
                  </>
                </Box>
              } style={{ marginLeft: "10px" }}>
                <InfoIcon/>
              </Tooltip>
            </Grid>
          </Grid>

          {fileNames && fileNames.length > 0 && (
            <List>
              {fileNames.map(fileName => (
                <ListItem key={fileName} secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => removeFileByName(fileName) }>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <ListItemText primary={fileName}/>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      }
      actions={
        <>
          { permissions?.media?.makeAvailableToCreators && (
            <FormControlLabel control={<Checkbox color={"primary"} checked={availableToCreators} onChange={(e) => setAvailableToCreators(e.target.checked)}/>} label={"Share with creator accounts"}/>
          )}
          <Button disabled={files === null || files.length === 0 || isUploading} onClick={doUpload}>Upload</Button>
          <Button disabled={isUploading} onClick={async () => {
            reset()
            await onDismiss()
          }}>Cancel</Button>
          <ProgressIndicator isUploading={isUploading} progress={progress}/>
        </>
      }
    />
  )
}