import React, {FC, SetStateAction, useEffect, useState} from "react";
import {MediaStorageItemDataFull} from "../../api/types";
import {GridContext} from "../Reusable/InfiniteItemGrid";
import {useAppContext} from "../../contexts/PermissionContext";
import {
  mediaStorageDelete, mediaStorageSetAvailability,
  mediaStorageUpdateLabel
} from "../../api/services";
import {toast} from "react-toastify";
import {LayoutWithSidebarWrapper, sidebarStyles} from "../Reusable/LayoutWithSidebars";
import {DialogConfirmation} from "../Reusable/DialogConfirmation";
import {DialogTextField} from "../Reusable/DialogTextField";
import {SidebarItemSummary} from "../Reusable/SidebarItemSummary";
import {Checkbox, Divider, FormControlLabel} from "@mui/material";
import {Button} from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';

export const GallerySidebar: FC<{
  selectedItem: MediaStorageItemDataFull,
  setSelectedItem: React.Dispatch<SetStateAction<MediaStorageItemDataFull>>,
  context: GridContext,
  tab: string
}> = ({
  selectedItem,
  setSelectedItem,
  context,
  tab,
}) => {


  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)
  const [isEditLabelDialogOpen, setEditLabelDialogOpen] = useState(false)

  const [label, setLabel] = useState("")
  const [isAvailableToCreators, setIsAvailableToCreators] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const { permissions } = useAppContext()

  useEffect(() => {
    setLabel(selectedItem?.label ?? "")
    setIsAvailableToCreators(selectedItem?.availability === 'MembersAndCreators')
    setIsEditable(permissions?.overall?.organizationMember || selectedItem?.availability === 'Members')
  }, [selectedItem])

  const deleteItem = async (item) => {
    await mediaStorageDelete(item.id)
    toast.success("Asset deleted successfully")
    context.refreshGrid()
  }

  const editLabel = async () => {
    await mediaStorageUpdateLabel(selectedItem.id, { label: label })
    selectedItem.label = label
    setLabel(selectedItem?.label ?? "")
    toast.success("Asset updated successfully")
    context.refreshGrid()
  }

  const dismissLabelEdit = () => {
    setEditLabelDialogOpen(false)
    setLabel(selectedItem?.label ?? "")
  }

  const triggerAvailableToCreators = async (available) => {
    if (available) {
      mediaStorageSetAvailability(selectedItem.id, 'MembersAndCreators').then((d) => {
        toast.success("Asset made available to creators")
        selectedItem.availability = d.data.availability
        setIsAvailableToCreators(available)
      })
    } else {
      mediaStorageSetAvailability(selectedItem.id, 'Members').then((d) => {
        toast.success("Asset made unavailable to creators")
        selectedItem.availability = d.data.availability
        setIsAvailableToCreators(available)
      })
    }
  }

  const markEducational = () => {
    mediaStorageSetAvailability(selectedItem.id, 'EveryoneEducational').then(() => {
      toast.success("Video moved to educational tab")
      context.refreshGrid()
    })
  }

  const markNonEducational = () => {
    mediaStorageSetAvailability(selectedItem.id, 'Members').then(() => {
      toast.success("Video moved to regular tab")
      context.refreshGrid()
    })
  }

  return (
    <LayoutWithSidebarWrapper>
      <DialogConfirmation
        title={"Are you sure?"}
        description={"Are you sure you want to delete this asset?"}
        yesTitle={"Delete"}
        noTitle={"Cancel"}
        open={isDeleteConfirmationDialogOpen}
        onPressYes={() => deleteItem(selectedItem)}
        onPressNo={() => setDeleteConfirmationDialogOpen(false) }
        onDismiss={() => setDeleteConfirmationDialogOpen(false) }
      />
      <DialogTextField
        title={"Change asset label"}
        description={""}
        yesTitle={"Save"}
        noTitle={"Cancel"}
        open={isEditLabelDialogOpen}
        textFieldLabel={"Asset label"}
        value={label}
        setValue={setLabel}
        onPressYes={editLabel}
        onPressNo={dismissLabelEdit}
        onDismiss={dismissLabelEdit}
      />

      { selectedItem && (
        <>
          <SidebarItemSummary name={label} created={selectedItem.createdAt} extra1={selectedItem.metadata["size"]}/>
          { isEditable && (
            <>
              <Divider sx={sidebarStyles.divider}/>
              { permissions?.media?.updateMedia && (
                <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={() => setEditLabelDialogOpen(true) }>Rename</Button>
              )}
              { permissions?.media?.deleteMedia && (
                <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={() => setDeleteConfirmationDialogOpen(true) }>Delete</Button>
              )}
              { tab === "videos" && permissions?.media?.manageEducationalVideos && selectedItem?.type === "VIDEO" && selectedItem?.availability !== 'EveryoneEducational' && (
                <>
                  <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={markEducational}>Move to educational tab</Button>
                </>
              )}
              { permissions?.media?.makeAvailableToCreators && tab !== "educational" && (
                <>
                  <Divider sx={sidebarStyles.divider}/>
                  <FormControlLabel control={<Checkbox color={"primary"} checked={isAvailableToCreators} onChange={(e) => triggerAvailableToCreators(e.target.checked)}/>} label={"Share with creator accounts"}/>
                  <ShareIcon sx={{margin: "-9px" }}/>
                </>
              )}
              { tab === "educational" && permissions?.media?.manageEducationalVideos && selectedItem?.type === "VIDEO" && (
                <>
                  <Button sx={sidebarStyles.mainButton} variant={"outlined"} onClick={markNonEducational}>Move to regular tab</Button>
                </>
              )}

            </>
          )}
          <Divider sx={sidebarStyles.divider}/>
          <a href={selectedItem.location} download target="_blank"><Button sx={sidebarStyles.mainButton} variant={"outlined"}>Download</Button></a>

        </>
      )}

    </LayoutWithSidebarWrapper>
  )
}
