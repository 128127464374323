import {FC} from "react";
import {Divider, Grid, Typography} from "@mui/material";
import DataChart, {DataChartDefinition, disabledTextColor} from "../Charts/DataChart";

export type ChartLayout = "Standard" | "Compact"

export const ChartBlock: FC<{
  definition: DataChartDefinition,
  summaryValues: {
    title: string,
    value: string,
  }[],
  layout: ChartLayout,
  disabled: boolean,
}> = ({
  definition,
  summaryValues,
  layout,
  disabled,
}) => {

  let chartSm = layout === "Standard" && summaryValues?.length > 0 ? 8 : 12
  let summarySm = layout === "Compact" ? 12 : 4

  return (
    <Grid container spacing={0} sx={{ marginBottom: "32px" }}>
      <Grid item xs={12} sm={chartSm} sx={{ height: "240px" }}>
        <DataChart
          disabled={disabled}
          type={definition.type}
          title={definition.title}
          data={definition.data}
          series={definition.series}
          productGrouping={definition.productGrouping}
          yAxis={definition.yAxis}
          noDataText={definition.noDataText}
        />
      </Grid>
      { summaryValues?.length > 0 && (
          <Grid item xs={12} sm={summarySm} direction={"column"} sx={layout === "Compact" ? {} : {marginTop: "40px" }}>
            <Grid container spacing={1}>
              { summaryValues.map(v => (
                <Grid item xs={12} sm={layout === "Compact" ? 4 : 12}>
                  <Typography variant={"h6"} style={{ color: disabled ? disabledTextColor : "#000" }}>
                    <span style={{fontSize: "24px",}}>{v.value}</span> {v.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
      )}
      <Grid item xs={12} sx={{ marginTop: "32px" }}>
        <Divider sx={{ paddingTop: "0px", height: "3px" }} />
      </Grid>
    </Grid>
  )
}