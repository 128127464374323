import React, {FC, useEffect, useState} from "react";
import {gridContextEmpty} from "../Reusable/InfiniteItemGrid";
import {LayoutWithSidebar} from "../Reusable/LayoutWithSidebars";
import {GallerySidebar} from "./GallerySidebar";
import {GalleryMain} from "./GalleryMain";

export const Gallery: FC<{}> = ({}) => {


  const [selectedItem, setSelectedItem] = useState(null)
  const [sidebarOpen, setSidebarOpen] = useState(true)

  const [tab, setTab] = useState("videos")

  // @ts-ignore
  const [context, setContext] = useState(gridContextEmpty)

  useEffect(() => {
    setSidebarOpen(Boolean(selectedItem))
  }, [selectedItem])

  useEffect(() => {
    if (!sidebarOpen) {
      setSelectedItem(null)
    }
  }, [sidebarOpen])

  return (
    <>
      <LayoutWithSidebar
        main={<GalleryMain
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          context={context}
          tab={tab}
          setTab={setTab}
        />}
        sidebar={<GallerySidebar
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          context={context}
          tab={tab}
        />}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </>
  )
}

