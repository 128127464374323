import { ReactNode } from 'react'

import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded'
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import { IActiveOrganizationState } from '../../../../redux/slices/organizationSlice'

export interface MenuItem {
  link?: string
  icon?: ReactNode
  badge?: string
  items?: MenuItem[]
  name: string
  resetSequenceData?: boolean
  active?: boolean
  // permission may render this menu item unavailable
  available: (state: IActiveOrganizationState) => boolean
}

export interface MenuItems {
  items: MenuItem[]
  heading: string
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Summary',
        icon: AutoGraphRoundedIcon,
        link: '/summary',
        available: state => {
          return state.permissions?.menu?.analytics === true
        },
      },
      {
        name: 'Account',
        link: '/account',
        icon: SettingsSuggestOutlinedIcon,
        available: () => {
          return true
        },
        items: [
          {
            name: 'User settings',
            link: '/account/user-settings',
            available: state => {
              return (
                state.permissions?.menu?.user ||
                state.permissions?.menu?.creatorProfile
              )
            },
          },
          {
            name: 'Organization',
            link: '/account/organization',
            available: state => {
              return state.permissions?.menu?.organization === true
            },
          },
          {
            name: 'Team',
            link: '/account/team',
            available: state => {
              return state.permissions?.menu?.team === true
            },
          },
          {
            name: 'Subscription',
            link: '/account/billing/subscription',
            available: state => {
              return state.permissions?.menu?.billing === true
            },
          },
          {
            name: 'Integration',
            link: '/account/integration',
            available: state => {
              return state.permissions?.menu?.integration === true
            },
          },
        ],
      },
      {
        name: 'Create',
        icon: AddRoundedIcon,
        link: '/create-content',
        resetSequenceData: true,
        available: state => {
          return state.permissions?.experience?.createExperience === true
        },
      },
      {
        name: 'Manage',
        icon: CenterFocusWeakRoundedIcon,
        link: '/content',
        available: state => {
          return (
            state.permissions?.menu?.experiences === true ||
            state.permissions?.menu?.brands === true
          )
        },
        items: [
          {
            name: 'Brands',
            link: '/content/brands',
            available: state => {
              return state.permissions?.menu?.brands === true
            },
          },
          {
            name: 'Gallery',
            link: '/content/gallery',
            available: state => {
              return state.permissions?.menu?.media === true
            }
          },
          {
            name: 'Live streams',
            link: '/content/livestreams',
            available: state => {
              return state.permissions?.menu?.liveStreams === true
            },
          },
          {
            name: 'Experiences',
            link: '/content/experiences',
            available: state => {
              return state.permissions?.menu?.experiences === true
            },
          },
          {
            name: 'Autopilot',
            link: '/content/autopilot',
            available: state => {
              return false
            },
          },
        ],
      },
      {
        name: 'Creators',
        icon: AutoAwesomeRoundedIcon,
        link: '/creators',
        available: state => {
          return state.permissions?.menu?.creatorManagement === true
        },
        items: [
          {
            name: 'Overview',
            link: '/creators/overview',
            available: state => {
              return state.permissions?.menu?.creatorManagement === true
            },
          },
          {
            name: 'Creators',
            link: '/creators/list',
            available: state => {
              return state.permissions?.menu?.creatorManagement === true
            },
          },
          // {
          //   name: 'Creators 2',
          //   link: '/creators2',
          //   available: state => {
          //     return state.permissions?.menu?.creatorManagement === true
          //   },
          // },
          {
            name: 'Live streams',
            link: '/creators/livestreams',
            available: state => {
              return state.permissions?.creatorManagement?.viewCreatorLivestreams === true
            },
          },
          {
            name: 'Experiences',
            link: '/creators/experiences',
            available: state => {
              return state.permissions?.creatorManagement?.viewCreatorExperiences === true
            },
          },
        ],
      },
      {
        name: 'Admin',
        icon: AdminPanelSettingsIcon,
        link: '/admin',
        available: state => {
          return state.permissions?.menu?.admin === true
        },
        items: [
          {
            name: 'Organizations',
            link: '/admin/organizations',
            available: state => {
              return state.permissions?.admin?.manageOrganizations === true
            },
          },
          {
            name: 'Affiliate relationships',
            link: '/admin/affiliateRelationships',
            available: state => {
              return state.permissions?.admin?.manageOrganizations === true
            },
          },
          {
            name: 'Platform actions',
            link: '/admin/platformActions',
            available: state => {
              return state.permissions?.admin?.managePlatformActions === true
            },
          },
          {
            name: 'Adhoc actions',
            link: '/admin/adhocActions',
            available: state => {
              return state.permissions?.admin?.manageAdhocActions === true
            },
          },
        ],
      },
    ],
  },
]

export default menuItems
