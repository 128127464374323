import {FC, useState} from "react";
import {EstablishedRelationship, MissingRelationship} from "../../api/types";
import {
  awinRelationshipsGetEstablished,
  awinRelationshipsGetMissing
} from "../../api/services";
import {ContesterDataTable, ContesterDataTableState} from "../Reusable/ContesterDataTable";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import {Card, CardContent, Typography} from "@mui/material";
import {DialogEstablishRelationship} from "./DialogEstablishRelationship";


export const AdminAffiliateRelationships: FC<{

}> = ({

}) => {

  const [selectedMissingRelationship, setSelectedMissingRelationship] = useState<MissingRelationship>()
  const [selectedEstablishedRelationship, setSelectedEstablishedRelationship] = useState<EstablishedRelationship>()

  const [missingRelationshipsTableState, setMissingRelationshipsTableState] = useState<ContesterDataTableState<MissingRelationship>>({
    columns: [
      {
        label: "Advertiser organization name",
        name: "advertiserOrganizationName",
      },
      {
        label: "Publisher organization name",
        name: "publisherOrganizationName",
      },
      {
        label: "advertiserId",
        name: "advertiserId",
      },
      {
        label: "publisherId",
        name: "publisherId",
      },
      {
        label: "commissionSchemeId",
        name: "commissionSchemeId",
      },
    ],
    fetch: (page, perPage) => awinRelationshipsGetMissing({ page: page, perPage: perPage }).then(d => d.data),
    onRowClick: (item) => {
      setSelectedMissingRelationship(item)
    }
  })


  const [establishedRelationshipsTableState, setEstablishedRelationshipsTableState] = useState<ContesterDataTableState<EstablishedRelationship>>({
    columns: [
      {
        label: "Advertiser organization name",
        name: "advertiserOrganizationName",
      },
      {
        label: "Publisher organization name",
        name: "publisherOrganizationName",
      },
      {
        label: "Advertiser Id",
        name: "advertiserId",
      },
      {
        label: "Publisher Id",
        name: "publisherId",
      },
      {
        label: "Commission Scheme Id",
        name: "commissionSchemeId",
      },
      {
        label: "Money management",
        name: "moneyManagement",
      },
      {
        label: "Total commission",
        name: "totalCommission",
      },
      {
        label: "Platform commission",
        name: "platformCommission",
      },
      {
        label: "Publisher organization commission",
        name: "publisherOrganizationCommission",
      },
      {
        label: "Individual creator commission",
        name: "individualCreatorCommission"
      }
    ],
    fetch: (page, perPage) => awinRelationshipsGetEstablished({ page: page, perPage: perPage }).then(d => d.data),
    onRowClick: (item) => {
      setSelectedEstablishedRelationship(item)
    }
  })

  const onDialogDismiss = (reload: boolean) => {
    setSelectedMissingRelationship(undefined)
    setSelectedEstablishedRelationship(undefined)
    if (reload) {
      setEstablishedRelationshipsTableState({
        ...establishedRelationshipsTableState,
        refresher: new Date().getMilliseconds().toString(),
      })
      setMissingRelationshipsTableState({
        ...missingRelationshipsTableState,
        refresher: new Date().getMilliseconds().toString(),
      })
    }
  }

  return (
    <ContentWrapper title={"Awin relationships"}>
      <DialogEstablishRelationship missingRelationship={selectedMissingRelationship} establishedRelationship={selectedEstablishedRelationship} onDismiss={onDialogDismiss} />
      <Card>
        <CardContent>
          <Typography sx={{ margin: "10px" }} variant={"h1"}>Organizations missing proper Awin relationships</Typography>
          <ContesterDataTable
            state={missingRelationshipsTableState}
            setState={setMissingRelationshipsTableState}
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography sx={{ margin: "10px" }} variant={"h1"}>Established Awin relationships</Typography>
          <ContesterDataTable
            state={establishedRelationshipsTableState}
            setState={setEstablishedRelationshipsTableState}
          />
        </CardContent>
      </Card>
    </ContentWrapper>
  )
}