import React, {FC, useEffect, useState} from 'react'
import {DashboardCharts, LiveStreamSingleData, SequencePollPreviewData} from '../../api/types'
import { useAppContext } from '../../contexts/PermissionContext'
import {
  liveStreamsGetChartsData,
  liveStreamsGetSingle, sequencePollGetForUpdate,
  uiGetConstants,
} from '../../api/services'
import {
  Card,
  Grid,
  Typography,
} from '@mui/material'
import { Box } from '@mui/material'
import Moment from 'react-moment'
import UserChoiceTable from '../Dashboard/Tables/UserChoiceTable'
import { LiveChat } from './LiveChat'
import { LiveIcon } from './LiveStreamGridComponent'

import MuxPlayer from "@mux/mux-player-react";
import {
  ActiveUsersDataChart,
  ConversionDataChart, EmailEventsDataChart,
  PageViewsDataChart,
  SalesDataChart, SocialDataChart, SubscriptionsDataChart,
  TimeSpentDataChart,
} from "../Dashboard/Charts";
import {VisitorPieCharts} from "../Dashboard/VisitorPieCharts";
import {ContentLayoutWithTabs} from "../Reusable/ContentLayoutWithTabs";
import SequenceBox from "../SequenceBox/SequenceBox";
import {prepareSequencePollForRedux} from "../../utils/sequenceSliceHelper";
import {setSequenceSliceData} from "../../redux/slices/sequenceSlice";
import {useDispatch} from "react-redux";

export const LiveStreamsLiveRoom: FC<{}> = ({}) => {
  const { permissions, getQueryParam } = useAppContext()
  const dispatch = useDispatch()

  const [liveStream, setLiveStream] = useState<LiveStreamSingleData>()
  const [experience, setExperience] = useState<SequencePollPreviewData | undefined>(undefined)

  const [currentLiveStreamUrl, setCurrentLiveStreamUrl] = useState(null)
  const [topUserChoices, setTopUserChoices] = useState(null)
  const [chartsData, setRealtimeCharts] = useState<DashboardCharts>()

  const [centerTab, setCenterTab] = useState<"userClicks" | "autopilot">("userClicks")

  const [liveroomDataUpdateFrequencySeconds, setLiveroomDataUpdateFrequencySeconds] = useState(10)
  const [liveroomChatUpdateFrequencySeconds, setLiveroomChatUpdateFrequencySeconds] = useState(5)
  const [realTimeUpdatesEnabled, setRealTimeUpdatesEnabled] = useState(false)

  const [noChartsDataText, setNoChartsDataText] = useState("Chart contains no data")

  useEffect(() => {
    if (liveStream) {
      let liveStreamUrl = liveStream.playbackUrl

      if (liveStream.status === 'Online') {
        setNoChartsDataText("Chart disabled during live stream")
      } else {
        setNoChartsDataText("Chart contains no data")
      }

      if (
        liveStream.status === 'Online' &&
        currentLiveStreamUrl !== liveStreamUrl
      ) {
        setCurrentLiveStreamUrl(liveStreamUrl)
      } else if (liveStream.status != 'Online' && currentLiveStreamUrl) {
        setCurrentLiveStreamUrl(null)
      }

      setExperience(liveStream.assignedExperiences?.[0])
    }
    setRealTimeUpdatesEnabled(liveStream?.status === "Online")
  }, [liveStream]);

  useEffect(() => {
    if (experience) {
      sequencePollGetForUpdate(experience.id).then(d => {
        let reduxData = prepareSequencePollForRedux(d.data, experience.id, { canEdit: false, resetLiveStreamIds: false })
        dispatch(setSequenceSliceData(reduxData))
      })

    }
  }, [experience]);

  useEffect(() => {
    const fetchData = () => {
      liveStreamsGetSingle(getQueryParam('id')).then(d => {
        d.data.status = "Online"
        setLiveStream(d.data)
        setTopUserChoices(d.data.statistics.topUserChoices)
        liveStreamsGetChartsData(d.data.id).then(c => {
          setRealtimeCharts(c.data)
        })
      })

      uiGetConstants().then(d => {
        let params = d.data.globalParams
        setLiveroomChatUpdateFrequencySeconds(params.livechatUpdateFrequencySeconds)
        setLiveroomDataUpdateFrequencySeconds(params.liveroomUpdateFrequencySeconds)
      })
    }

    fetchData()

    if (realTimeUpdatesEnabled) {
      let interval = setInterval(fetchData, liveroomDataUpdateFrequencySeconds * 1000)
      return () => clearInterval(interval)
    }
  }, [getQueryParam('id'), liveroomDataUpdateFrequencySeconds, realTimeUpdatesEnabled])

  let styles = {
    columnContainer: {
      height: `${window.innerHeight-112}px`,
      overflowY: "scroll",
      padding: "10px",
    },
    infoItem: {
      fontSize: '14px',
      fontWeight: 'bold',
    },
    video: {
      width: '100%',
      maxHeight: '600px',
      borderRadius: '10px',
      minHeight: '300px',
      marginBottom: '16px',
    },
    title: {
      fontSize: '14px',
    },
  }

  return (
    <>
      {liveStream && (
        <Box sx={{padding: "10px"}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}>
              <Card sx={styles.columnContainer}>
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={10}
                    flexDirection="row"
                    justifyContent="space-around"
                  >
                    <Typography sx={styles.infoItem}>
                      {liveStream.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <LiveIcon status={liveStream.status} currentViewers={liveStream.statistics.currentViewers.displayValue} currentLatency={liveStream.viewerLatency.displayValue} />
                  </Grid>
                  <Grid
                    item
                    display="flex"
                    xs={12}
                    flexDirection="row"
                    justifyContent="space-around"
                  >
                    <Grid item xs={6}>
                      <Typography sx={styles.title}>Scheduled at:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={styles.infoItem}>
                        <Moment format="DD MMM YYYY[,] HH:mm">
                          {liveStream.scheduledAt * 1000}
                        </Moment>
                      </Typography>{' '}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={styles.infoItem}>
                    { experience && (
                      <>
                        <a
                          target={'_blank'}
                          href={experience.ownerType === 'Creator' && permissions.overall.organizationMember ?
                            `/creators/experiences?id=${experience.id}` :
                            `/content/experiences?id=${experience.id}`}
                        >
                          Shopping experience {'>>'}
                        </a>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={styles.title}>
                      Unique subscribers:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={styles.infoItem}>
                      {liveStream.subscribers.uniqueSubscribers.displayValue}
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography sx={styles.title}>
                      Total subscribers:
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography sx={styles.infoItem}>
                      {liveStream.subscribers.totalSubscribers.displayValue}
                    </Typography>
                  </Grid>
                </Grid>
                { experience && (
                  <LiveChat
                    organizationId={experience.organizationId}
                    referenceId={liveStream.id}
                    updateFrequencySeconds={liveroomChatUpdateFrequencySeconds}
                    realTimeUpdatesEnabled={realTimeUpdatesEnabled}
                  />
                )}
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={styles.columnContainer}>
                <MuxPlayer
                  streamType={liveStream?.latencyMode === 'LowLatency' ? "ll-live" : "live"}
                  playbackId={currentLiveStreamUrl?.replace("https://stream.mux.com/", "")?.replace(".m3u8", "")}
                  style={styles.video}
                />
                <ContentLayoutWithTabs tab={centerTab} setTab={setCenterTab} tabs={[
                  {
                    title: "User clicks",
                    name: "userClicks",
                    children:
                      <UserChoiceTable
                        userChoices={topUserChoices}
                        rowsToShow={5}
                        defaultRowsToShow={5}
                        type={'Compact'}
                      />
                  },
                  {
                    title: "Autopilot",
                    name: "autopilot",
                    children:
                      <SequenceBox mode={"Interactive"} experienceId={experience?.id}/>
                  }
                ]}/>

              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card sx={styles.columnContainer}>
                { chartsData && (
                  <>
                    <ActiveUsersDataChart data={chartsData} layout={"Compact"} />
                    {/*<LiveStreamDataChart data={chartsData} layout={"Compact"} />*/}
                    <TimeSpentDataChart data={chartsData} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                    <PageViewsDataChart data={chartsData} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                    { (permissions?.organization?.sales || permissions?.creatorManagement?.sales) && (
                      <>
                        <SalesDataChart data={chartsData} liveStreamSales={true} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                        <ConversionDataChart data={chartsData} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                      </>
                    )}
                    <SubscriptionsDataChart data={chartsData} uniqueSubscribers={true} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                    <EmailEventsDataChart data={chartsData} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                    <SocialDataChart data={chartsData} layout={"Compact"} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                    <VisitorPieCharts charts={chartsData} md={4} noDataText={noChartsDataText} disabled={liveStream?.status == "Online"} />
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}