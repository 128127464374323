import React, {FC, SetStateAction, useState} from "react";
import {MediaStorageItemDataFull} from "../../api/types";
import {GridContext, InfiniteItemGrid} from "../Reusable/InfiniteItemGrid";
import {useAppContext} from "../../contexts/PermissionContext";
import {ContentLayoutWithTabs} from "../Reusable/ContentLayoutWithTabs";
import {mediaStorageGetEducationalVideosList, mediaStorageGetList} from "../../api/services";
import {GalleryImageComponent, GalleryVideoComponent} from "./GalleryItemComponent";
import {Button} from "@mui/material";
import CloudUpload from "@mui/icons-material/CloudUpload";
import InstagramIcon from '@mui/icons-material/Instagram';
import {UnsplashDialog} from "./UnsplashDialog";
import {GalleryUploadDialog} from "./GalleryUploadDialog";
import {InstagramDialog} from "./InstagramDialog";


export const GalleryMain: FC<{
  selectedItem: MediaStorageItemDataFull,
  setSelectedItem: React.Dispatch<SetStateAction<MediaStorageItemDataFull>>,
  context: GridContext,
  tab: string,
  setTab: React.Dispatch<React.SetStateAction<string>>,
}> = ({
  selectedItem,
  setSelectedItem,
  context,
  setTab,
  tab,
}) => {

  const [videos, setVideos] = useState(null)
  const [images, setImages] = useState(null)
  const [educational, setEducational] = useState(null)

  context.refreshGrid = () => {
    setVideos(null)
    setImages(null)
    setEducational(null)
  }

  const unsplashSelected = async () => {
    setImages(null)
    setTab("images")
  }

  const instagramSelected = async (item: MediaStorageItemDataFull) => {
    if (item.type === 'IMAGE') {
      setImages(null)
      setTab("images")
    } else {
      setVideos(null)
      setTab("videos")
    }
  }

  const { permissions } = useAppContext()

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  const [unsplashDialogOpen, setUnsplashDialogOpen] = useState(false)
  const [instagramDialogOpen, setInstagramDialogOpen] = useState(false)


  let perPage = 24
  return (
    <ContentLayoutWithTabs tab={tab} setTab={setTab} tabs={[
      {
        name: 'videos',
        title: 'Videos',
        children:
          <InfiniteItemGrid
            perPage={perPage}
            loadItems={(page, perPage) => mediaStorageGetList({ type: 'VIDEO', page: page, perPage: perPage }).then(d => d.data)}
            scrollRef={document.getElementById("sidebar-layout-main-content-area")}
            items={videos}
            setItems={setVideos}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            component={(item, selected) => <GalleryVideoComponent selected={selected} item={item} controls={true}/>}
          />
      },
      {
        name: 'images',
        title: 'Images',
        children:
          <InfiniteItemGrid
            perPage={perPage}
            loadItems={(page, perPage) => mediaStorageGetList({ type: 'IMAGE', page: page, perPage: perPage }).then(d => d.data)}
            scrollRef={document.getElementById("sidebar-layout-main-content-area")}
            items={images}
            setItems={setImages}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            component={(item, selected) => <GalleryImageComponent selected={selected} item={item}/>}
          />
      },
      {
        name: 'educational',
        title: 'Educational',
        children:
          <InfiniteItemGrid
            perPage={perPage}
            loadItems={(page, perPage) => mediaStorageGetEducationalVideosList({ page: page, perPage: perPage }).then(d => d.data)}
            scrollRef={document.getElementById("sidebar-layout-main-content-area")}
            items={educational}
            setItems={setEducational}
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
            component={(item, selected) => <GalleryVideoComponent selected={selected} item={item} controls={true}/>}
          />
      },
    ]} controls={
      <>
        { permissions?.media?.createMedia && (
          <>
            { permissions?.media?.useUnsplash && tab === "images" && (
              <Button color={"secondary"} variant={"outlined"} sx={{marginRight: "10px", marginTop: "5px"}} startIcon={<CloudUpload/>} onClick={() => setUnsplashDialogOpen(true) }>
                Unsplash
              </Button>
            )}
            { permissions?.media?.useInstagram && false && (
              <Button color={"error"} variant={"outlined"} sx={{marginTop: "5px", borderColor: "rgba(110, 117, 159, 0.5)"}} startIcon={<InstagramIcon/>} onClick={() => setInstagramDialogOpen(true) }>
                Instagram
              </Button>
            )}
            { tab !== "educational" && (
              <Button color={"primary"} variant={"outlined"} sx={{marginRight: "10px", marginTop: "5px" }} startIcon={<CloudUpload/>} onClick={() => setUploadDialogOpen(true) }>
                Upload
              </Button>
            )}
            <UnsplashDialog open={unsplashDialogOpen} onSelect={unsplashSelected} type={"GALLERY"} onDismiss={() => {
              setUnsplashDialogOpen(false)
            }}/>
            { permissions?.media?.useInstagram && false && (
                <InstagramDialog open={instagramDialogOpen} onSelect={instagramSelected} onDismiss={() => {
                  setInstagramDialogOpen(false)
                }}/>
            )}

            <GalleryUploadDialog open={uploadDialogOpen} onUpload={() => {
              setUploadDialogOpen(false)
              setImages(null)
              setVideos(null)
            }} onDismiss={() => {
              setUploadDialogOpen(false)
            }}/>
          </>
        )}
      </>
    }/>
  )
}