import React, {useEffect} from "react";
import MUIDataTable, {MUIDataTableColumnDef} from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export interface ContesterDataTableNumberedList<T> {
  content: T[]
  page: number
  perPage: number
  totalElements: number
  totalPages: number
}

export interface ContesterDataTableState<T> {
  columns: MUIDataTableColumnDef[],
  fetch: (page: number, perPage: number, sortColumn: string, sortDirection: string) => Promise<ContesterDataTableNumberedList<T>>,
  page?: number,
  perPage?: number,
  sortColumn?: string,
  sortDirection?: string,
  count?: number,
  data?: T[],
  onRowClick?: (item: T) => void,
  refresher?: string,
}

export const ContesterDataTable = <T, >(props: {
  state: ContesterDataTableState<T>,
  setState: React.Dispatch<React.SetStateAction<ContesterDataTableState<T>>>,
}) => {

  const getData = () => {
    props.state.fetch(props.state.page, props.state.perPage, props.state.sortColumn, props.state.sortDirection).then(d => {
      props.setState({
        ...props.state,
        page: d.page,
        perPage: d.perPage,
        count: d.totalElements,
        data: d.content,
      })
    })
  }

  useEffect(() => {
    getData()
  }, [props.state.page, props.state.perPage, props.state.sortColumn, props.state.sortDirection, props.state.refresher])

  // @ts-ignore
  let getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides:{
          root: {
            cursor: "pointer"
          }
        }
      }
    }
  })

  return (
    <ThemeProvider theme={getMuiTheme()}>
      <MUIDataTable
        data={props.state.data}
        columns={props.state.columns}
        options={{
          selectableRows: "none",
          onRowClick: (rowData, rowMeta) => {
            if (props.state.onRowClick) {
              props.state.onRowClick(props.state.data.at(rowMeta.rowIndex))
            }
          },
          filter: true,
          filterType: 'dropdown',
          responsive: 'vertical',
          serverSide: true,
          count: props.state.count,
          rowsPerPage: props.state.perPage,
          rowsPerPageOptions: [10, 25, 100],
          sort: false,
          // sortOrder: { },
          onTableChange: (action, tableState) => {
            console.log(action, tableState);

            switch (action) {
              case 'changePage':
                props.setState({
                  ...props.state,
                  page: tableState.page
                })
                break;
              case 'sort':
                props.setState({
                  ...props.state,
                  sortColumn: tableState.sortOrder.name,
                  sortDirection: tableState.sortOrder.direction,
                })
                break;
              case 'changeRowsPerPage':
                props.setState({
                  ...props.state,
                  perPage: tableState.rowsPerPage
                })
                break;
              default:
                console.log(`action ${action} not handled.`);
            }
          },
        }}
      />
    </ThemeProvider>
  )
}