import React, {FC, ReactNode} from "react";
import {TextField} from "@mui/material";


export const DialogSimpleFieldText: FC<{
  label: string,
  value: string,
  setValue: React.Dispatch<React.SetStateAction<string>>,
  helperText?: string | ReactNode,
  error: boolean,
  errorText?: string,
  multiline?: boolean,
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void,
  onBlurCapture?: (e: React.FocusEvent<HTMLDivElement>) => void,
  variant?: "filled" | "outlined" | "standard",
  disabled?: boolean,
  type?: React.InputHTMLAttributes<unknown>['type'],
  maxLength?: number,
  onEnterPress?: () => void,
}> = ({
  label,
  value,
  setValue,
  helperText = "",
  error,
  errorText = "Field is required",
  multiline = false,
  onKeyDown,
  onBlurCapture,
  variant,
  disabled = false,
  type = "text",
  maxLength,
  onEnterPress,
}) => {

  let approachesLength = value && maxLength && value.length + 6 > maxLength
  let exceedsLength = value && maxLength && value.length > maxLength

  let resolvedHelperText = helperText
  if (error) {
    resolvedHelperText = errorText
  } else if (approachesLength) {
    let appendix = `(allowed text length: ${value.length}/${maxLength})`
    if (helperText) {
      resolvedHelperText = `${helperText}, ${appendix}`
    } else {
      resolvedHelperText = appendix
    }
  }

  return (
    <TextField
      margin={"dense"}
      variant={variant ?? "standard"}
      maxRows={5}
      label={label}
      value={value}
      onKeyDownCapture={(e) => {
        if (e.key === 'Enter' && onEnterPress) {
          onEnterPress()
        }
      }}
      onChange={(e) => {
        let val = e.target.value
        if (!maxLength || val.length <= maxLength) {
          setValue(val)
        }
      }}
      onKeyDown={(e) => onKeyDown?.(e)}
      onBlurCapture={(e) => onBlurCapture?.(e)}
      type={type}
      fullWidth
      error={error || exceedsLength}
      disabled={disabled}
      helperText={resolvedHelperText}
      multiline={multiline}
    />
  )
}