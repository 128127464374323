import React, {FC, useState} from "react";
import ContentWrapper from "../ContentWrapper/ContentWrapper";
import {Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Typography} from "@mui/material";
import {DialogSimpleFieldText} from "../Reusable/DialogSimpleFieldText";
import {adminAdhocVerifyUrls} from "../../api/services";
import {AdminAdhocVerifyUrlsResponse} from "../../api/types";
import SyncIcon from '@mui/icons-material/Sync';
export const AdminAdhocActions: FC<{

}> = ({

}) => {

  const [urls, setUrls] = useState("")

  const [urlResults, setUrlResults] = useState<AdminAdhocVerifyUrlsResponse>()
  const [isLoading, setIsLoading] = useState(false)
  const [bypassCache, setBypassCache] = useState(false)

  const verifyUrls = async () => {
    setIsLoading(true)
    let r = await adminAdhocVerifyUrls({ urls: urls.split("\n"), bypassCache: bypassCache })
    setUrlResults(r.data)
    setIsLoading(false)
  }

  return (
    <ContentWrapper title={"Platform adhoc actions"}>
      <Card>
        <CardContent>
          <Typography variant={"h2"}>URL checker</Typography>
          <DialogSimpleFieldText label={"urls (one per line)"} multiline={true} value={urls} setValue={setUrls} error={false}/>
          <FormGroup>
            <FormControlLabel control={<Checkbox color={"primary"} checked={bypassCache} onChange={(e) => setBypassCache(e.target.checked)}/>} label={"Bypass cache (loads slower too)"}/>
          </FormGroup>
          <Button variant={"contained"} onClick={verifyUrls} disabled={!urls || isLoading} endIcon={ isLoading && <SyncIcon/> }>Verify</Button>
          { urlResults && (
            <Grid container sx={{marginTop: "20px" }}>
              <Grid item xs={4}>
                <table>
                  <thead>
                  <tr>
                    <td>
                      Url
                    </td>
                  </tr>
                  </thead>
                  <hr/>
                  <tbody>
                  {urlResults.urls.map(r => (
                    <tr>
                      <td>
                        {r.url}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={4}>
                <table>
                  <thead>
                  <tr>
                    <td>
                      Can embed
                    </td>
                  </tr>
                  </thead>
                  <hr/>
                  <tbody>
                  {urlResults.urls.map(r => (
                    <tr>
                      <td>
                        {r.canEmbed.toString()}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={4}>
                <table>
                  <thead>
                  <tr>
                    <td>
                      Can overlay
                    </td>
                  </tr>
                  </thead>
                  <hr/>
                  <tbody>
                  {urlResults.urls.map(r => (
                    <tr>
                      <td>
                        {r.canOverlay.toString()}
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </ContentWrapper>
  )
}